@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

body {
    background: #F5F5F5;
}

a {
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}

.container {
    padding-left: 10%;
    padding-right: 10%;
}

.btn {
    background: #FFF;
    color: #212121;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn img {
    width: 20px;
    margin-left: 10px;
}

.btn.dark-btn {
    background: #212EA0;
    color: #FFF;
}

/* Media Query */

@media (max-width: 1200px) {
    .container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .btn {
        padding: 14px 22px;
    }
}