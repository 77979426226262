.pop-up-gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.pop-up-gallery img {
    width: 23%;
    height: 300px;
    margin: 5px 5px;
    border: 4px solid #fff;
}

.pop-up-gallery.hide-gallery {
    display: none;
}

/* Media Query */

@media (max-width: 800px) {
    .pop-up-gallery {
        flex-wrap: wrap;
    }

    .pop-up-gallery img {
        width: 45%;
        margin-bottom: 20px;
    }
}

@media (max-width: 500px) {
    .pop-up-gallery {
        flex-wrap: wrap;
    }

    .pop-up-gallery img {
        width: 80%;
        margin-bottom: 20px;
    }
}