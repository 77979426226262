.pop-up-service {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-up-service h2 {
    color: #fff;
}

.pop-up-service.hide-service {
    display: none;
}