nav {
    width: 100%;
    /* background: #333; */
    color: #fff;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.logo {
    width: 180px;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
}

.dark-nav {
    background: #212EA0;
    transition: 0.5s;
}

.menu-icon {
    display: none;
}

/* Media Query */

@media (max-width: 1000px) {
    .logo {
        width: 140px;
    }

    nav ul li {
        margin: 10px 15px;
    }
}

@media (max-width: 840px) {
    nav {
        padding: 15px 0;
    }

    nav ul {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: #212EA0;
        z-index: -1;
        width: 200px;
        padding-top: 70px;
        transition: 0.5s;
    }

    nav ul li {
        display: block;
        margin: 25px 40px;
    }

    .menu-icon {
        display: block;
        width: 30px;
        cursor: pointer;
    }

    .hide-mobile-menu {
        right: -200px;
    }
}